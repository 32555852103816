import { preparationPlanBackplate } from "../../style.constants";
import { pageTitle } from "../../style.utilities";
const preparationPlanStyles = {
    preparationPlan: {
        backgroundColor: preparationPlanBackplate,
        minHeight: "100vh",
        height: "100%",
        fontFamily: "Roboto, Arial, sans-serif",
    },
    preparationPlan_checklistTitle: {
        padding: "20px",
        color: "white",
        fontSize: "2.5em",
        textAlign: "center"
    },
    preparationPlan_title: Object.assign({}, pageTitle, { marginBottom: "0" }),
    preparationPlan_subtitle: {
        textAlign: "center",
        color: "white",
        fontSize: "2.5em",
        fontWeight: "400",
        padding: "0 3em 0 3em"
    }
};
export default preparationPlanStyles;
