import React from "react";
import { get } from "lodash-es";
import Foundation from "@microsoft/fast-components-foundation-react";
export default class Card extends Foundation {
    constructor() {
        super(...arguments);
        this.handledProps = {
            image: void 0,
            title: void 0,
            content: void 0,
            managedClasses: void 0,
        };
    }
    render() {
        return (React.createElement("div", { className: this.generateClassNames() },
            React.createElement("div", { className: get(this.props, "managedClasses.card_imageRegion") }, this.props.image),
            React.createElement("div", { className: get(this.props, "managedClasses.card_contentRegion") },
                React.createElement("h2", { className: get(this.props, "managedClasses.card_title") }, this.props.title),
                React.createElement("p", { className: get(this.props, "managedClasses.card_content") }, this.props.content))));
    }
    generateClassNames() {
        return super.generateClassNames(get(this.props, "managedClasses.card"));
    }
}
Card.displayName = "Card";
