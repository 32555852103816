import { get } from "lodash-es";
import { Language } from "./action-types";
const initialState = {
    language: Language.es
};
export const languageReducer = (state = initialState, action) => {
    if (typeof get(action, "data") === "string") {
        return action.data;
    }
    return state.language;
};
