import React from "react";
export class PreparationPlanLogo extends React.Component {
    render() {
        return (React.createElement("svg", { width: "125", height: "125", viewBox: "0 0 110 100", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M97.5357 0C97.3329 0 97.1302 0 97.1302 0H23.3194C16.4249 0 10.95 5.46559 10.95 12.1457V77.5304C4.86665 77.9352 0 82.7935 0 88.8664C0 91.9028 1.21666 94.9393 3.44721 96.9636C5.67776 98.9879 8.31386 100 11.3555 100C11.5583 100 11.5583 100 11.7611 100H66.9164H76.8525C76.8525 100 76.8525 100 77.0553 100C80.2997 100 83.5441 98.583 85.7747 96.1538C88.208 93.7247 89.4246 90.6883 89.4246 87.4494V29.7571H107.269C108.486 29.7571 109.5 28.7449 109.5 27.7328V12.1457C109.5 5.66802 104.227 0.202429 97.5357 0Z", fill: "#004E89" }),
            React.createElement("path", { d: "M85.3692 9.7166C85.3692 9.91903 85.3692 10.1215 85.3692 10.3239V10.5263V10.7287C85.3692 10.9312 85.3692 10.9312 85.3692 11.1336V11.336C85.3692 11.5385 85.3692 11.5385 85.3692 11.7409V11.9433C85.1665 11.9433 85.1665 12.1458 85.1665 12.1458V87.6518C85.1665 92.1053 81.5165 95.749 76.8526 95.9514C76.6498 95.9514 76.6498 95.9514 76.4471 95.9514C72.9999 95.749 70.161 92.7125 69.9582 89.2713C69.9582 89.0688 69.9582 89.0688 69.9582 88.8664C69.9582 85.83 71.986 82.996 74.8248 81.9838C75.636 81.7814 76.4471 81.5789 77.4609 81.5789C78.6776 81.5789 79.6915 80.5668 79.6915 79.5547C79.6915 78.3401 78.6776 77.5304 77.4609 77.5304H14.8029V12.1458C14.8029 7.69231 18.4528 4.04858 23.1167 4.04858H88.2081C86.7887 5.66802 85.9776 7.48988 85.3692 9.7166Z", fill: "#F8D0B2" }),
            React.createElement("path", { d: "M35.0803 47.3685H27.5776C26.3609 47.3685 25.5498 48.3807 25.5498 49.3928V56.6803C25.5498 57.8948 26.5637 58.7046 27.5776 58.7046H35.0803C36.297 58.7046 37.1081 57.6924 37.1081 56.6803V49.3928C37.1081 48.3807 36.297 47.3685 35.0803 47.3685ZM33.4581 55.2633H29.1998V51.0123H33.4581V55.2633Z", fill: "#004E89" }),
            React.createElement("path", { d: "M35.0803 62.1458H27.5776C26.3609 62.1458 25.5498 63.1579 25.5498 64.17V71.4575C25.5498 72.6721 26.5637 73.4818 27.5776 73.4818H35.0803C36.297 73.4818 37.1081 72.4696 37.1081 71.4575V64.17C37.1081 63.1579 36.297 62.1458 35.0803 62.1458ZM33.4581 70.0405H29.1998V65.7895H33.4581V70.0405Z", fill: "#004E89" }),
            React.createElement("path", { d: "M69.1469 50.6073H43.3942C42.3803 50.6073 41.3665 51.6194 41.3665 52.6316C41.3665 53.8462 42.1776 54.6559 43.3942 54.6559H69.1469C70.1608 54.6559 71.1747 53.6437 71.1747 52.6316C71.1747 51.6194 70.3636 50.6073 69.1469 50.6073Z", fill: "#004E89" }),
            React.createElement("path", { d: "M69.1469 34.8177H43.3942C42.3803 34.8177 41.3665 35.8299 41.3665 36.842C41.3665 38.0566 42.1776 38.8663 43.3942 38.8663H69.1469C70.1608 38.8663 71.1747 37.8542 71.1747 36.842C71.1747 35.6275 70.3636 34.8177 69.1469 34.8177Z", fill: "#004E89" }),
            React.createElement("path", { d: "M69.1469 19.0283H43.3942C42.3803 19.0283 41.3665 20.0405 41.3665 21.0526C41.3665 22.2672 42.1776 23.0769 43.3942 23.0769H60.0219H69.3497C70.3636 23.0769 71.3775 22.0648 71.3775 21.0526C71.1747 19.838 70.3636 19.0283 69.1469 19.0283Z", fill: "#004E89" }),
            React.createElement("path", { d: "M69.1469 66.3969H43.3942C42.3803 66.3969 41.3665 67.409 41.3665 68.4211C41.3665 69.6357 42.1776 70.4454 43.3942 70.4454H69.1469C70.1608 70.4454 71.1747 69.4333 71.1747 68.4211C71.1747 67.409 70.3636 66.3969 69.1469 66.3969Z", fill: "#004E89" }),
            React.createElement("path", { d: "M37.7164 16.3968C36.9053 15.5871 35.4859 15.5871 34.6748 16.3968L30.4164 20.4454L29.4025 19.6357C28.5914 18.826 27.172 18.826 26.3609 19.6357C25.5498 20.4454 25.5498 21.66 26.3609 22.4697L28.997 24.6964C29.8081 25.5062 31.2275 25.5062 32.0387 24.6964C32.2414 24.6964 32.2414 24.494 32.2414 24.494L37.7164 19.2308C38.5275 18.4211 38.5275 17.2066 37.7164 16.3968Z", fill: "#F1683F" }),
            React.createElement("path", { d: "M37.7164 33.1984C36.9053 32.3886 35.4859 32.3886 34.6748 33.1984L30.4165 37.2469L29.1998 36.4372C28.3887 35.6275 26.9692 35.6275 26.1581 36.4372C25.347 37.2469 25.347 38.4615 26.1581 39.2712L28.7942 41.4979C29.6053 42.3077 31.0248 42.3077 31.8359 41.4979C32.0387 41.4979 32.0387 41.2955 32.0387 41.2955L37.5136 36.0324C38.5275 35.2226 38.5275 34.0081 37.7164 33.1984Z", fill: "#F1683F" }),
            React.createElement("path", { d: "M6.48885 93.1174C5.06941 91.9028 4.2583 90.0809 4.2583 88.2591C4.2583 84.4129 7.50273 81.3765 11.5583 81.3765H67.9303C66.7136 82.7935 65.9025 84.4129 65.4969 86.2348H18.4527C17.236 86.2348 16.4249 87.0445 16.4249 88.2591C16.4249 89.4736 17.4388 90.2833 18.4527 90.2833H65.4969C65.9025 92.1052 66.7136 93.7246 67.9303 95.1417C50.4914 95.1417 12.1666 95.1417 11.5583 95.1417C9.73328 94.9392 7.90829 94.3319 6.48885 93.1174Z", fill: "#F2F2D9" }),
            React.createElement("path", { d: "M12.1666 89.0688C12.5721 88.6639 12.7749 88.0566 12.7749 87.6518C12.7749 87.4493 12.7749 87.4493 12.7749 87.2469C12.7749 87.0445 12.7749 87.0445 12.5721 86.8421C12.5721 86.6396 12.3694 86.6396 12.3694 86.4372C12.3694 86.2348 12.1666 86.2348 12.1666 86.0323C11.761 85.6275 10.9499 85.2226 10.3416 85.4251C10.1388 85.4251 10.1388 85.4251 9.93604 85.6275C9.73326 85.6275 9.73326 85.8299 9.53049 85.8299C9.32771 85.8299 9.32771 86.0323 9.12493 86.0323C9.12493 86.0323 8.92216 86.2348 8.92216 86.4372C8.92216 86.6396 8.71938 86.6396 8.71938 86.8421C8.71938 87.0445 8.71938 87.0445 8.5166 87.2469C8.5166 87.4493 8.5166 87.4493 8.5166 87.6518C8.5166 87.8542 8.5166 87.8542 8.5166 88.0566C8.5166 88.2591 8.5166 88.2591 8.71938 88.4615C8.71938 88.6639 8.92216 88.6639 8.92216 88.8664C8.92216 89.0688 9.12493 89.0688 9.12493 89.2712C9.12493 89.2712 9.32771 89.4736 9.53049 89.4736C9.73326 89.4736 9.73326 89.6761 9.93604 89.6761C10.1388 89.6761 10.1388 89.6761 10.3416 89.8785C10.5444 89.8785 10.5444 89.8785 10.7471 89.8785C10.9499 89.8785 10.9499 89.8785 11.1527 89.8785C11.3555 89.8785 11.3555 89.8785 11.5583 89.6761C11.761 89.6761 11.761 89.4736 11.9638 89.4736C11.9638 89.2712 11.9638 89.2712 12.1666 89.0688Z", fill: "#004E89" }),
            React.createElement("path", { d: "M105.241 26.3158H89.2218V12.5506C89.2218 12.3482 89.2218 12.1458 89.2218 12.1458C89.2218 12.1458 89.2218 12.1458 89.2218 11.9433V11.7409V11.5385V11.336V11.1336C89.2218 11.1336 89.2218 11.1336 89.2218 10.9312C89.4246 9.71661 90.0329 8.50204 90.844 7.48989C91.2496 7.28746 91.2496 7.08503 91.4523 7.08503C92.2635 6.07289 93.2773 5.26317 94.494 4.85831C94.494 4.85831 94.6968 4.85831 94.6968 4.65588C94.6968 4.65588 94.6968 4.65588 94.8996 4.65588H95.1023H95.3051H95.5079H95.7107C95.7107 4.65588 95.7107 4.65588 95.9134 4.65588C96.1162 4.65588 96.1162 4.65588 96.319 4.65588C96.5218 4.65588 96.5218 4.65588 96.7246 4.65588C96.7246 4.65588 96.7245 4.65588 96.9273 4.65588C97.1301 4.65588 97.1301 4.65588 97.3329 4.65588C97.3329 4.65588 97.3329 4.65588 97.5357 4.65588C101.997 4.85831 105.444 8.50204 105.444 12.9555V26.3158H105.241Z", fill: "#4783B1" })));
    }
}
PreparationPlanLogo.displayName = "PreparationPlanLogo";
