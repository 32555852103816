import React from "react";
import { get } from "lodash-es";
import Foundation from "@microsoft/fast-components-foundation-react";
import { store } from "../../app";
import { connect } from "react-redux";
import { Language } from "../../store/action-types";
import { languageAction } from "../../store/actions";
class LanguageSelect extends Foundation {
    constructor() {
        super(...arguments);
        this.handledProps = {
            language: void 0,
            managedClasses: void 0,
        };
        this.handleUpdateLanguage = (e) => {
            store.dispatch(languageAction(e.target.value));
        };
    }
    render() {
        return (React.createElement("div", { className: get(this.props, "managedClasses.languageSelect") },
            React.createElement("select", { value: this.props.language, onChange: this.handleUpdateLanguage },
                React.createElement("option", { value: Language.en }, "English"),
                React.createElement("option", { value: Language.es }, "Espa\u00F1ol"))));
    }
}
LanguageSelect.displayName = "LanguageSelect";
const mapStateToProps = (state) => {
    return {
        language: state.language
    };
};
export default connect(mapStateToProps)(LanguageSelect);
