import React from "react";
import { get } from "lodash-es";
import { connect } from "react-redux";
import Foundation from "@microsoft/fast-components-foundation-react";
import { MenuBar } from "../";
import { pages } from "../../strings";
import { Carousel } from '@microsoft/fast-components-react-msft';
import { createCarouselSlidesFromCards } from "../../utilities";
class YourRights extends Foundation {
    constructor() {
        super(...arguments);
        this.handledProps = {
            language: void 0,
            managedClasses: void 0,
        };
    }
    render() {
        return (React.createElement("div", { className: this.generateClassNames() },
            React.createElement(MenuBar, { logo: true }),
            React.createElement("h1", { className: get(this.props, "managedClasses.yourRights_title") }, pages.pageTitles[this.props.language].yourRights),
            this.renderCarousel()));
    }
    generateClassNames() {
        return super.generateClassNames(get(this.props, "managedClasses.yourRights"));
    }
    renderCarousel() {
        return (React.createElement(Carousel, { label: "Carousel", items: createCarouselSlidesFromCards(pages.yourRights[this.props.language].cards) }));
    }
}
YourRights.displayName = "YourRights";
const mapStateToProps = (state) => {
    return {
        language: state.language
    };
};
export default connect(mapStateToProps)(YourRights);
