import React from "react";
import { YourRights, YourRightsLogo, YourResponsibilities, YourResponsibilitiesLogo, PreparationPlan, PreparationPlanLogo, KidsLikeYou, KidsLikeYouLogo, Landing, SmallLogo } from "./components";
import { yourRightsBackplate, yourResponsibilitiesBackplate, kidsLikeYouBackplate, preparationPlanBackplate } from "./style.constants";
import { Language } from "./store/action-types";
export const paths = [
    {
        path: "/",
        component: Landing,
        displayName: {
            en: React.createElement(SmallLogo, { kindTextColor: "white" }),
            es: React.createElement(SmallLogo, { kindTextColor: "white", language: Language.es })
        }
    },
    {
        path: "/your-rights",
        component: YourRights,
        displayName: {
            en: "Your Rights",
            es: "Tus Derechos"
        },
        landingLogo: React.createElement(YourRightsLogo, null),
        landingLogoBackplate: yourRightsBackplate
    },
    {
        path: "/your-responsibilities",
        component: YourResponsibilities,
        displayName: {
            en: "Your Responsibilities",
            es: "Tus Responsabilidades"
        },
        landingLogo: React.createElement(YourResponsibilitiesLogo, null),
        landingLogoBackplate: yourResponsibilitiesBackplate
    },
    {
        path: "/preparation-plan",
        component: PreparationPlan,
        displayName: {
            en: "Preparation Plan",
            es: "Plan de Preparación",
        },
        landingLogo: React.createElement(PreparationPlanLogo, null),
        landingLogoBackplate: preparationPlanBackplate
    },
    {
        path: "/kids-like-you",
        component: KidsLikeYou,
        displayName: {
            en: "Kids Like You",
            es: "Jovenes como Tú"
        },
        landingLogo: React.createElement(KidsLikeYouLogo, null),
        landingLogoBackplate: kidsLikeYouBackplate
    }
];
