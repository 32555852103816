import manageJss from "@microsoft/fast-jss-manager-react";
import BaseYourResponsibilities from "./your-responsibilities";
import yourResponsibilitiesStyles from "./your-responsibilities.style";
/*
 * The type returned by manageJss type is very complicated so we'll let the
 * compiler infer the type instead of re-declaring just for the package export
 */
/* tslint:disable-next-line:typedef */
const YourResponsibilities = manageJss(yourResponsibilitiesStyles)(BaseYourResponsibilities);
export { YourResponsibilities };
export * from "./your-responsibilities.logo";
