import React from "react";
import { get } from "lodash-es";
import Foundation from "@microsoft/fast-components-foundation-react";
import { MenuBar } from "../";
import { pages } from "../../strings";
import { connect } from "react-redux";
import { TodoItem } from "../todo-item";
class PreparationPlan extends Foundation {
    constructor() {
        super(...arguments);
        this.handledProps = {
            language: void 0,
            managedClasses: void 0,
        };
    }
    render() {
        return (React.createElement("div", { className: this.generateClassNames() },
            React.createElement(MenuBar, { logo: true }),
            React.createElement("h1", { className: get(this.props, "managedClasses.preparationPlan_title") }, pages.pageTitles[this.props.language].preparationPlan),
            React.createElement("h2", { className: get(this.props, "managedClasses.preparationPlan_subtitle") }, pages.preparationPlan[this.props.language].subTitle),
            this.renderChecklist(),
            this.renderSupplementaryChecklistTitle(),
            this.renderSupplementaryChecklist()));
    }
    generateClassNames() {
        return super.generateClassNames(get(this.props, "managedClasses.preparationPlan"));
    }
    renderChecklist() {
        return pages.preparationPlan[this.props.language].checklist.map((todoProps) => {
            return (React.createElement(TodoItem, Object.assign({}, todoProps)));
        });
    }
    renderSupplementaryChecklistTitle() {
        return (React.createElement("div", { className: this.props.managedClasses.preparationPlan_checklistTitle }, pages.preparationPlan[this.props.language].supplementaryChecklistTitle));
    }
    renderSupplementaryChecklist() {
        return pages.preparationPlan[this.props.language].supplementaryChecklist.map((todoProps) => {
            return (React.createElement(TodoItem, Object.assign({}, todoProps)));
        });
    }
}
PreparationPlan.displayName = "PreparationPlan";
const mapStateToProps = (state) => {
    return {
        language: state.language
    };
};
export default connect(mapStateToProps)(PreparationPlan);
