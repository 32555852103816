import React from "react";
import { get } from "lodash-es";
import Foundation from "@microsoft/fast-components-foundation-react";
import { Link } from "react-router-dom";
import { paths } from "../../path.constants";
import { connect } from "react-redux";
class Menu extends Foundation {
    constructor(props) {
        super(props);
        this.handledProps = {
            language: void 0,
            managedClasses: void 0,
        };
        this.handleTriggerClick = (e) => {
            this.setState({
                open: !this.state.open
            });
        };
        this.state = {
            open: false
        };
    }
    render() {
        return (React.createElement("div", { className: this.generateClassNames() },
            React.createElement("button", { className: get(this.props, "managedClasses.menu_trigger"), onClick: this.handleTriggerClick },
                React.createElement("span", null)),
            React.createElement("div", { className: get(this.props, "managedClasses.menu_list") },
                React.createElement("ul", null, this.renderLinks()))));
    }
    renderLinks() {
        return paths.map((path, index) => {
            return (React.createElement("li", { key: index },
                React.createElement(Link, { to: path.path }, path.displayName[this.props.language])));
        });
    }
    generateClassNames() {
        let classes = get(this.props, "managedClasses.menu");
        if (this.state.open) {
            classes = `${classes} ${get(this.props, "managedClasses.menu__open")}`;
        }
        return super.generateClassNames(classes);
    }
}
Menu.displayName = "Menu";
const mapStateToProps = (state) => {
    return {
        language: state.language
    };
};
export default connect(mapStateToProps)(Menu);
