import React from "react";
import { Provider } from "react-redux";
import { HashRouter, Switch, Route } from "react-router-dom";
import Foundation from "@microsoft/fast-components-foundation-react";
import { combineReducers, createStore } from "redux";
import * as Reducers from "./store/reducers";
import { paths } from "./path.constants";
export const store = createStore(combineReducers({
    language: Reducers.languageReducer
}));
export default class App extends Foundation {
    render() {
        return (React.createElement(Provider, { store: store },
            React.createElement(HashRouter, null,
                React.createElement("div", null,
                    React.createElement(Switch, null, this.renderRoutes())))));
    }
    renderRoutes() {
        return paths.map((path, index) => {
            return React.createElement(Route, { key: index, path: path.path, component: path.component, exact: true });
        });
    }
}
App.displayName = "App";
