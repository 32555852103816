import React from "react";
import { Menu, SmallLogo, LanguageSelect } from "../";
import { accentColor } from "../../style.constants";
import { connect } from "react-redux";
class MenuBar extends React.Component {
    render() {
        return (React.createElement("div", { style: { display: "flex", backgroundColor: "white", marginBottom: "20px", height: "120px" } },
            React.createElement(Menu, null),
            this.renderLogo(),
            React.createElement(LanguageSelect, null)));
    }
    renderLogo() {
        if (this.props.logo) {
            return (React.createElement("div", { style: { textAlign: "left", flexGrow: 1, marginTop: "13px" } },
                React.createElement(SmallLogo, { kindTextColor: accentColor, padding: "25px 0", language: this.props.language })));
        }
        return React.createElement("div", { style: { flexGrow: 1 } });
    }
}
MenuBar.displayName = "MenuBar";
const mapStateToProps = (state) => {
    return {
        language: state.language
    };
};
const menuBar = connect(mapStateToProps)(MenuBar);
export { menuBar as MenuBar };
