import React from "react";
import { get } from "lodash-es";
import Foundation from "@microsoft/fast-components-foundation-react";
import { connect } from "react-redux";
class TodoItem extends Foundation {
    constructor() {
        super(...arguments);
        this.handledProps = {
            title: void 0,
            content: void 0,
            language: void 0,
            managedClasses: void 0,
        };
    }
    render() {
        return (React.createElement("div", { className: this.generateClassNames() },
            React.createElement("div", { className: this.props.managedClasses.todoItem_inputRegion },
                React.createElement("div", null,
                    React.createElement("input", { type: "checkbox" }),
                    React.createElement("span", null))),
            React.createElement("div", { className: this.props.managedClasses.todoItem_contentRegion },
                React.createElement("h2", null, this.props.title),
                this.props.content &&
                    React.createElement("p", null, this.props.content))));
    }
    generateClassNames() {
        return super.generateClassNames(get(this.props, "managedClasses.todoItem"));
    }
}
TodoItem.displayName = "TodoItem";
const mapStateToProps = (state) => {
    return {
        language: state.language
    };
};
export default connect(mapStateToProps)(TodoItem);
