import React from "react";
import { get } from "lodash-es";
import { Link } from "react-router-dom";
import Foundation from "@microsoft/fast-components-foundation-react";
import { MenuBar } from "../";
import { LargeLogo } from "../large-logo";
import { paths } from "../../path.constants";
import { connect } from "react-redux";
class Landing extends Foundation {
    constructor() {
        super(...arguments);
        this.handledProps = {
            language: void 0,
            managedClasses: void 0,
        };
    }
    render() {
        return (React.createElement("div", { className: this.generateClassNames() },
            React.createElement(MenuBar, null),
            React.createElement("div", { className: get(this.props, "managedClasses.landing_contentRegion") },
                React.createElement("div", { className: get(this.props, "managedClasses.landing_contentRegionLogo") },
                    React.createElement(LargeLogo, { language: this.props.language })),
                React.createElement("ul", { className: get(this.props, "managedClasses.landing_contentRegionList") }, this.renderLinks()))));
    }
    renderLinks() {
        return paths.map((path, index) => {
            if (path.landingLogoBackplate) {
                return (React.createElement("li", { key: index, style: { backgroundColor: path.landingLogoBackplate } },
                    React.createElement(Link, { to: path.path },
                        path.landingLogo,
                        React.createElement("h1", { className: get(this.props, "managedClasses.landing_contentRegionTitle") }, path.displayName[this.props.language]))));
            }
        });
    }
    generateClassNames() {
        return super.generateClassNames(get(this.props, "managedClasses.landing"));
    }
}
Landing.displayName = "Landing";
const mapStateToProps = (state) => {
    return {
        language: state.language
    };
};
export default connect(mapStateToProps)(Landing);
