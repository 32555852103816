import React from "react";
import { get } from "lodash-es";
import Foundation from "@microsoft/fast-components-foundation-react";
import { MenuBar } from "../";
import { pages } from "../../strings";
import { connect } from "react-redux";
class KidsLikeYou extends Foundation {
    constructor() {
        super(...arguments);
        this.handledProps = {
            language: void 0,
            managedClasses: void 0,
        };
    }
    render() {
        return (React.createElement("div", { className: this.generateClassNames() },
            React.createElement(MenuBar, { logo: true }),
            React.createElement("h1", { className: get(this.props, "managedClasses.kidsLikeYou_title") }, pages.pageTitles[this.props.language].kidsLikeYou),
            this.renderVideoCards()));
    }
    generateClassNames() {
        return super.generateClassNames(get(this.props, "managedClasses.kidsLikeYou"));
    }
    renderVideoCards() {
        return pages.kidsLikeYou[this.props.language].video.map((video, index) => {
            return (React.createElement("div", { key: index, className: get(this.props, "managedClasses.kidsLikeYou_card") },
                video.embeddedVideo,
                React.createElement("div", { className: get(this.props, "managedClasses.kidsLikeYou_cardContentRegion") },
                    React.createElement("h2", { className: get(this.props, "managedClasses.kidsLikeYou_cardTitle") }, video.title),
                    React.createElement("p", { className: get(this.props, "managedClasses.kidsLikeYou_cardContent") }, video.content))));
        });
    }
}
KidsLikeYou.displayName = "KidsLikeYou";
const mapStateToProps = (state) => {
    return {
        language: state.language
    };
};
export default connect(mapStateToProps)(KidsLikeYou);
