import { accentColor } from "../../style.constants";
/* changable vars */
const height = 12;
const rectSideX = 90;
const rectSizeY = 60;
const transitionDuration = "0.3s";
const borderRadius = "3px";
/* calculated vars */
const boxHeight = rectSizeY;
const boxWidth = rectSideX;
const horizontalCenter = (boxHeight / 2) - (height / 2);
const verticalCenter = height * 2;
const menuStyles = {
    menu: {
        display: "inline-block",
        "& $menu_trigger": {
            "& span": {
                top: `${verticalCenter}px`,
                left: "0"
            },
            "&::before": {
                top: "0",
                left: "0"
            },
            "&::after": {
                bottom: "0",
                left: "0"
            }
        },
        "& $menu_list": {
            display: "none"
        }
    },
    menu_list: {
        "& ul": {
            listStyle: "none",
            margin: "0",
            padding: "0",
        },
        "& li": {
            padding: "20px",
            "& a": {
                color: "white",
                textDecoration: "none",
                fontFamily: "Fredoka One, Arial, sans-serif",
                fontSize: "4em",
                fontWeight: "100"
            }
        }
    },
    menu__open: {
        display: "block",
        zIndex: "9999",
        textAlign: "center",
        position: "absolute",
        background: accentColor,
        height: "100vh",
        width: "100%",
        "& $menu_trigger": {
            "& span": {
                display: "none",
            },
            "&::before": {
                top: `${horizontalCenter}px`,
                left: "0",
                transform: "rotate(45deg)",
                backgroundColor: "white",
            },
            "&::after": {
                bottom: `${horizontalCenter}px`,
                left: "0",
                transform: "rotate(-45deg)",
                backgroundColor: "white",
            }
        },
        "& $menu_list": {
            display: "block"
        },
    },
    menu_trigger: {
        position: "relative",
        display: "block",
        width: `${boxWidth}px`,
        height: `${boxHeight}px`,
        margin: "22px",
        marginTop: "35px",
        border: "none",
        background: "transparent",
        "& span": {
            position: "absolute",
            height: `${height}px`,
            width: `${boxWidth}px`,
            backgroundColor: accentColor,
            transitionProperty: "transform",
            transitionDuration: transitionDuration,
            top: `${verticalCenter}px`,
            borderRadius: borderRadius
        },
        "&::before": {
            content: "''",
            width: `${boxWidth}px`,
            position: "absolute",
            height: `${height}px`,
            backgroundColor: accentColor,
            transitionProperty: "top, transform",
            transitionDuration: `${transitionDuration}, ${transitionDuration}`,
            top: `${verticalCenter}px`,
            borderRadius: borderRadius
        },
        "&::after": {
            content: "''",
            width: `${boxWidth}px`,
            position: "absolute",
            height: `${height}px`,
            backgroundColor: accentColor,
            transitionProperty: "bottom, transform",
            transitionDuration: `${transitionDuration}, ${transitionDuration}`,
            bottom: `${verticalCenter}px`,
            borderRadius: borderRadius
        }
    }
};
export default menuStyles;
