import { pageTitle } from "../../style.utilities";
const landingStyles = {
    landing: {
        backgroundColor: "#F2F2D9",
        minHeight: "100vh",
        height: "100%"
    },
    landing_contentRegion: {},
    landing_contentRegionTitle: Object.assign({}, pageTitle, { marginTop: "5px" }),
    landing_contentRegionList: {
        borderRadius: "5px",
        padding: "0",
        overflow: "hidden",
        margin: "20px",
        listStyle: "none",
        textAlign: "center",
        "& li": {
            padding: "2em 10px 10px 10px",
            "& a": {
                color: "white",
                textDecoration: "none",
                fontFamily: "Fredoka One, Arial, sans-serif"
            }
        }
    },
    landing_contentRegionLogo: {
        textAlign: "center"
    }
};
export default landingStyles;
