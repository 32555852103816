import React from "react";
export class YourResponsibilitiesLogo extends React.Component {
    render() {
        return (React.createElement("svg", { width: "125", height: "125", viewBox: "0 0 102 100", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { id: "Vector", d: "M95.0303 25.8C94.0321 25.8 93.0339 26 92.0357 26.4V11.8C92.0357 8 89.041 5 85.2478 5C84.2496 5 83.0517 5.2 82.2531 5.8C82.0535 4.4 81.2549 3.2 80.2567 2.2C78.8592 1 77.262 0.2 75.2656 0.2C71.4724 0.2 68.4777 3.4 68.4777 7V8C67.6791 7.6 66.6809 7.4 65.4831 7.4C61.6898 7.4 58.6952 10.4 58.6952 14.2V17.2C57.8966 16.8 56.8984 16.6 55.9002 16.6C54.1034 16.6 52.3066 17.2 50.9091 18.6C49.3119 17.2 47.5152 16.6 45.7184 16.6C44.7201 16.6 43.7219 16.8 42.9234 17.2V14.2C42.9234 10.4 39.9287 7.4 36.1355 7.4C35.1373 7.4 34.139 7.6 33.1408 8V6.8C33.1408 3 30.1462 0 26.3529 0C24.5562 0 22.7594 0.6 21.3619 2C20.3636 3 19.7647 4.2 19.3654 5.6C18.3672 5.2 17.369 4.8 16.3708 4.8C12.7772 4.8 9.78253 8 9.78253 11.8V26.4C8.98396 26 7.98574 25.8 6.78788 25.8C2.99465 25.8 0 28.8 0 32.6V57.6C0 63.4 2.79501 69 7.38681 72.4C8.58467 73.2 11.18 77.2 12.9768 80.4C12.3779 80.8 11.9786 81.4 11.9786 82.2V98C11.9786 99 12.7772 100 13.975 100H41.9251C43.123 100 43.9216 99.2 43.9216 98V83.4C45.5187 84.4 46.7166 85.2 47.7148 85.8C48.1141 86.2 48.5134 86.4 48.9127 86.6C49.5116 87 50.1105 87 50.7094 87C51.3084 87 51.9073 86.8 52.5062 86.6C52.9055 86.4 53.3048 86.2 53.7041 85.8C54.7023 85.2 56.0998 84.4 57.697 83.4V98C57.697 99 58.4955 100 59.6934 100H87.6435C88.6417 100 89.6399 99.2 89.6399 98V82.4C89.6399 81.6 89.2406 81 88.6417 80.6C90.4385 77.4 93.2335 73.4 94.2317 72.6C98.8235 69.2 101.619 63.6 101.619 57.8V32.6C101.818 28.8 98.8235 25.8 95.0303 25.8Z", fill: "#004E89" }),
            React.createElement("path", { id: "Vector_2", d: "M9.98222 69.6C6.38864 66.8 3.99292 62.4 3.99292 57.8V32.8C3.99292 31.2 5.39042 30 6.98757 30C8.58472 30 9.98222 31.2 9.98222 32.8V49.2C9.98222 50.2 10.7808 51.2 11.9787 51.2C13.1765 51.2 13.9751 50.4 13.9751 49.2V31.2V11.8C13.9751 10.2 15.3726 9 16.9697 9C18.5669 9 19.9644 10.2 19.9644 11.8V25.2C19.9644 26.2 20.763 27.2 21.9608 27.2C23.1587 27.2 23.9573 26.4 23.9573 25.2V7C23.9573 6.2 24.3566 5.4 24.9555 4.8C25.1551 4.4 25.9537 4 26.7523 4C28.3494 4 29.5473 5.4 29.5473 6.8V32.4C27.5509 33.2 25.7541 34.4 24.1569 35.8C20.3637 39.4 18.3673 44.4 18.3673 50.4C18.3673 52.4 18.5669 54 18.7665 55.8C17.968 55.6 16.9697 55.6 15.9715 55.6H15.7719C14.574 55.6 13.7755 56.4 13.7755 57.6C13.7755 58.6 14.574 59.6 15.7719 59.6H16.1712C17.968 59.6 19.5651 60 20.9626 60.8C21.1623 60.8 21.1623 61 21.3619 61C21.3619 61 21.3619 61 21.5615 61C21.7612 61.2 21.9608 61.2 21.9608 61.4C21.9608 61.4 21.9608 61.4 22.1605 61.4C22.3601 61.4 22.3601 61.6 22.5598 61.6C24.7558 63.4 26.1533 66 26.353 68.8C26.353 69 26.353 69.2 26.353 69.6V80.2H17.7683C16.1712 77.8 12.3779 71.4 9.98222 69.6Z", fill: "#F8D0B2" }),
            React.createElement("path", { id: "Vector_3", d: "M23.3582 30.8C23.3582 30.6 23.1586 30.6 23.1586 30.4C23.1586 30.2 22.9589 30.2 22.9589 30C22.9589 30 22.7593 29.8 22.5597 29.8C22.36 29.8 22.36 29.6 22.1604 29.6C21.9607 29.6 21.9607 29.6 21.7611 29.4C21.1621 29.2 20.3636 29.4 19.9643 30C19.9643 30 19.7646 30.2 19.7646 30.4C19.7646 30.6 19.565 30.6 19.565 30.8C19.565 31 19.565 31 19.3654 31.2C19.3654 31.4 19.3654 31.4 19.3654 31.6C19.3654 32.2 19.565 32.6 19.9643 33C19.9643 33 20.1639 33.2 20.3636 33.2C20.5632 33.2 20.5632 33.4 20.7629 33.4C20.9625 33.4 20.9625 33.4 21.1621 33.6C21.3618 33.6 21.3618 33.6 21.5614 33.6C21.7611 33.6 21.7611 33.6 21.9607 33.6C22.1604 33.6 22.1604 33.6 22.36 33.4C22.5597 33.4 22.5597 33.2 22.7593 33.2C22.9589 33.2 22.9589 33 23.1586 33C23.5579 32.6 23.7575 32 23.7575 31.6C23.7575 31.4 23.7575 31.4 23.7575 31.2C23.3582 31.2 23.3582 31 23.3582 30.8Z", fill: "#004E89" }),
            React.createElement("path", { id: "Vector_4", d: "M29.5474 80.5999V73.3999C32.1427 75.7999 34.9377 77.9999 37.7327 79.9999L37.3335 80.5999H29.5474Z", fill: "#F8D0B2" }),
            React.createElement("path", { id: "Vector_5", d: "M33.5402 14C33.5402 12.4 34.9377 11.2 36.5348 11.2C38.132 11.2 39.5295 12.4 39.5295 14V30.1999C39.3298 30.1999 39.1302 30.1999 38.9305 30.1999H38.7309C38.3316 30.1999 38.132 30.1999 37.7327 30.1999C36.3352 30.1999 34.738 30.4 33.5402 30.6V14Z", fill: "#F8D0B2" }),
            React.createElement("path", { id: "Vector_6", d: "M42.7236 23.4001C42.7236 21.8001 44.1211 20.6001 45.7183 20.6001C46.5169 20.6001 47.3154 20.8001 47.9144 21.4001C48.5133 22.0001 48.9126 22.8001 48.9126 23.6001V34.8001C48.7129 34.6001 48.7129 34.6001 48.5133 34.4001C48.5133 34.4001 48.5133 34.4001 48.3136 34.4001C48.114 34.2001 48.114 34.0001 47.9144 34.0001C47.7147 33.8001 47.5151 33.8001 47.5151 33.6001C47.5151 33.6001 47.5151 33.6001 47.3154 33.6001C47.1158 33.6001 46.9161 33.4001 46.9161 33.4001C46.7165 33.2001 46.5169 33.2001 46.3172 33.0001C46.3172 33.0001 46.3172 33.0001 46.1176 33.0001C45.9179 33.0001 45.7183 32.8001 45.5186 32.8001C45.319 32.8001 45.1194 32.6001 44.9197 32.6001H44.7201C44.5204 32.6001 44.3208 32.4001 44.1211 32.4001C43.9215 32.4001 43.7218 32.2001 43.5222 32.2001H43.3226C43.1229 32.2001 43.1229 32.2001 42.9233 32.0001V23.4001H42.7236Z", fill: "#F8D0B2" }),
            React.createElement("path", { id: "Vector_7", d: "M97.8252 58.0001C97.8252 62.6001 95.6292 67.0001 91.8359 69.8001C89.4402 71.6001 85.647 78.0001 84.0498 80.8001H75.2655V70.0001C75.2655 69.8001 75.2655 69.6001 75.2655 69.2001C75.4652 66.4001 76.8627 63.6001 79.0587 61.8001C79.2584 61.6001 79.2584 61.6001 79.458 61.6001C79.458 61.6001 79.458 61.6001 79.6577 61.6001C79.8573 61.4001 80.057 61.4001 80.057 61.2001C80.057 61.2001 80.057 61.2001 80.2566 61.2001C80.4563 61.2001 80.4563 61.0001 80.6559 61.0001C82.0534 60.2001 83.6505 59.8001 85.4473 59.8001H85.8466C87.0445 59.8001 87.8431 59.0001 87.8431 57.8001C87.8431 56.8001 87.0445 55.8001 85.8466 55.8001H85.4473C84.4491 55.8001 83.6506 55.8001 82.6523 56.0001C83.0516 54.4001 83.0516 52.6001 83.0516 50.6001C83.0516 48.8001 82.852 47.0001 82.4527 45.4001C81.6541 41.8001 79.8573 38.6001 77.262 36.2001C75.6648 34.8001 73.868 33.6001 71.8716 32.8001V7.00007C71.8716 5.40007 73.0694 4.20007 74.6666 4.20007C75.4652 4.20007 76.0641 4.40007 76.663 5.00007C77.262 5.60007 77.6612 6.40007 77.6612 7.20007V25.4001C77.6612 26.4001 78.4598 27.4001 79.6577 27.4001C80.8555 27.4001 81.6541 26.6001 81.6541 25.4001V11.8001C81.6541 10.2001 83.0516 9.00007 84.6488 9.00007C86.2459 9.00007 87.6434 10.2001 87.6434 11.8001V31.0001V49.0001C87.6434 50.0001 88.442 51.0001 89.6399 51.0001C90.8377 51.0001 91.6363 50.2001 91.6363 49.0001V32.6001C91.6363 31.0001 93.0338 29.8001 94.6309 29.8001C96.2281 29.8001 97.6256 31.0001 97.6256 32.6001V58.0001H97.8252Z", fill: "#F8D0B2" }),
            React.createElement("path", { id: "Vector_8", d: "M71.2727 73.3999V80.5999H63.4866L63.0873 79.9999C65.8823 77.9999 68.6773 75.7999 71.2727 73.3999Z", fill: "#F8D0B2" }),
            React.createElement("path", { id: "Vector_9", d: "M52.9055 23.4C52.9055 22.6 53.3048 21.8 53.9037 21.2C54.5027 20.6 55.3012 20.4 56.0998 20.4C57.697 20.4 59.0945 21.8 59.0945 23.4V31.6C58.8948 31.6 58.8948 31.6 58.6952 31.8H58.4955C58.2959 31.8 58.0962 32 57.8966 32C57.697 32 57.4973 32.2 57.2977 32.2H57.098C56.8984 32.2 56.6987 32.4 56.4991 32.4C56.2995 32.4 56.0998 32.6 55.9002 32.6C55.9002 32.6 55.9002 32.6 55.7005 32.6C55.5009 32.6 55.3012 32.8 55.1016 33C54.902 33 54.7023 33.2 54.7023 33.4C54.7023 33.4 54.7023 33.4 54.5027 33.4C54.303 33.6 54.1034 33.6 54.1034 33.8C53.9037 34 53.9037 34 53.7041 34.2C53.7041 34.2 53.7041 34.2 53.5044 34.2C53.3048 34.4 53.3048 34.4 53.1052 34.6V23.4H52.9055Z", fill: "#F8D0B2" }),
            React.createElement("path", { id: "Vector_10", d: "M63.0875 14C63.0875 12.4 64.485 11.2 66.0821 11.2C67.6793 11.2 69.0768 12.4 69.0768 14V30.6C68.8771 30.6 68.8771 30.6 68.6775 30.6C67.4796 30.4 66.0821 30.1999 64.6846 30.1999C64.2853 30.1999 64.0857 30.1999 63.6864 30.1999H63.4867C63.2871 30.1999 63.0875 30.1999 62.8878 30.1999V14H63.0875Z", fill: "#F8D0B2" }),
            React.createElement("path", { id: "Vector_11", d: "M59.0946 77.4C56.0999 79.4 53.3049 81.2 51.5081 82.2C51.3085 82.4 50.9092 82.6 50.7095 82.6C50.5099 82.4 50.1106 82.2 49.911 82C48.1142 81 45.5188 79.2 42.5242 77.2C37.3334 73.8 33.1409 70.4 30.1463 67.6C29.747 64.4 28.1498 61.4 25.9538 59.2C25.7541 59 25.7541 59 25.5545 58.8L25.3548 58.6C25.3548 58.6 25.1552 58.6 25.1552 58.4C24.9555 58.2 24.9555 58.2 24.7559 58C24.3566 57.8 23.9573 57.6 23.7577 57.2C23.7577 57.2 23.7577 57.2 23.558 57.2C22.7595 55 22.5598 52.8 22.5598 50.2C22.5598 39.4 30.1463 34.6 37.7327 34.6C38.5313 34.6 39.3299 34.6 40.1284 34.8C40.7274 34.8 41.1267 35 41.5259 35H41.7256C45.9181 36.2 47.9145 38.8 48.7131 40.6C48.7131 40.6 48.7131 40.6 48.7131 40.8C48.7131 40.8 48.7131 40.8 48.7131 41C48.9128 41.4 49.312 41.6 49.911 41.8C49.911 41.8 49.911 41.8 50.1106 41.8C50.1106 41.8 50.1106 41.8 50.3103 41.8H50.5099C50.7095 41.8 50.9092 41.8 51.3085 41.6H51.5081C51.5081 41.6 51.7078 41.6 51.7078 41.4C51.7078 41.4 51.9074 41.4 51.9074 41.2L52.1071 41L52.3067 40.8L52.5063 40.6C53.7042 38.4 56.6989 34.6 63.6864 34.6C71.2728 34.6 78.8593 39.4 78.8593 50.2C78.8593 52.4 78.6596 54.6 78.0607 56.4C78.0607 56.6 77.8611 57 77.8611 57.2C77.8611 57.2 77.8611 57.2 77.6614 57.2C77.4618 57.4 77.0625 57.6 76.8629 57.8L76.6632 58C76.4636 58.2 76.4636 58.2 76.2639 58.4C76.2639 58.4 76.0643 58.4 76.0643 58.6L75.8646 58.8C75.665 59 75.665 59 75.4653 59.2C73.2693 61.4 71.6721 64.4 71.2728 67.6C68.4778 70.6 64.2853 73.8 59.0946 77.4Z", fill: "#F38665" }),
            React.createElement("path", { id: "Vector_12", d: "M40.3279 84.8H15.9714V96H40.3279V84.8Z", fill: "#F2F2D9" }),
            React.createElement("path", { id: "Vector_13", d: "M86.2459 84.8H61.8894V96H86.2459V84.8Z", fill: "#F2F2D9" }),
            React.createElement("path", { id: "Vector_14", d: "M82.2531 30.7999C82.2531 30.5999 82.0534 30.5999 82.0534 30.3999C82.0534 30.1999 81.8538 30.1999 81.8538 29.9999C81.8538 29.9999 81.6541 29.7999 81.4545 29.7999C81.2548 29.7999 81.2548 29.5999 81.0552 29.5999C80.8556 29.5999 80.8556 29.5999 80.6559 29.3999C80.4563 29.3999 80.057 29.3999 79.8573 29.3999C79.6577 29.3999 79.6577 29.3999 79.4581 29.5999C79.2584 29.5999 79.2584 29.7999 79.0588 29.7999C78.8591 29.7999 78.8591 29.9999 78.6595 29.9999C78.6595 29.9999 78.4598 30.1999 78.4598 30.3999C78.4598 30.5999 78.2602 30.5999 78.2602 30.7999C78.2602 30.9999 78.2602 30.9999 78.0605 31.1999C78.0605 31.3999 78.0605 31.3999 78.0605 31.5999C78.0605 31.7999 78.0605 31.7999 78.0605 31.9999C78.0605 32.1999 78.0606 32.1999 78.2602 32.3999C78.2602 32.5999 78.4598 32.5999 78.4598 32.7999C78.4598 32.9999 78.6595 32.9999 78.6595 33.1999C78.6595 33.1999 78.8591 33.3999 79.0588 33.3999C79.2584 33.3999 79.2584 33.5999 79.4581 33.5999C79.6577 33.5999 79.6577 33.5999 79.8573 33.7999C80.057 33.7999 80.057 33.7999 80.2566 33.7999C80.4563 33.7999 80.4563 33.7999 80.6559 33.7999C80.8556 33.7999 80.8556 33.7999 81.0552 33.5999C81.2548 33.5999 81.2548 33.3999 81.4545 33.3999C81.6541 33.3999 81.6541 33.1999 81.8538 33.1999C81.8538 33.1999 82.0534 32.9999 82.0534 32.7999C82.0534 32.5999 82.2531 32.5999 82.2531 32.3999C82.2531 32.1999 82.2531 32.1999 82.4527 31.9999C82.4527 31.7999 82.4527 31.7999 82.4527 31.5999C82.4527 31.3999 82.4527 31.3999 82.4527 31.1999C82.4527 31.1999 82.4527 30.9999 82.2531 30.7999Z", fill: "#004E89" })));
    }
}
YourResponsibilitiesLogo.displayName = "YourResponsibilitiesLogo";
