import React from "react";
export class ConstitutionalRightsLogo extends React.Component {
    render() {
        return (React.createElement("svg", { width: "408", height: "408", viewBox: "0 0 136 136", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M98.2373 113.383C98.3003 113.532 98.3785 113.677 98.4695 113.813C98.5618 113.948 98.6675 114.074 98.7833 114.19C99.2498 114.653 99.8964 114.917 100.559 114.917C100.724 114.917 100.889 114.902 101.05 114.87C101.211 114.838 101.369 114.79 101.521 114.728C101.671 114.666 101.819 114.589 101.955 114.498C102.094 114.408 102.221 114.306 102.337 114.19C102.453 114.074 102.558 113.948 102.649 113.813C102.739 113.677 102.817 113.532 102.88 113.383C102.943 113.234 102.99 113.077 103.023 112.919C103.054 112.757 103.073 112.596 103.073 112.43C103.073 111.778 102.804 111.139 102.336 110.674C102.221 110.56 102.094 110.454 101.955 110.366C101.819 110.276 101.671 110.199 101.521 110.136C101.369 110.074 101.211 110.027 101.05 109.995C100.233 109.833 99.3656 110.097 98.7833 110.674C98.6649 110.788 98.5618 110.917 98.4695 111.052C98.3785 111.187 98.3003 111.332 98.2373 111.481C98.1749 111.631 98.1272 111.787 98.0934 111.946C98.0622 112.107 98.0476 112.27 98.0476 112.43C98.0476 112.596 98.0622 112.757 98.0934 112.919C98.1272 113.077 98.1749 113.234 98.2373 113.383", fill: "#004E89" }),
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M32.6098 18.7977C32.6715 18.9466 32.7511 19.0898 32.8402 19.2273C32.9305 19.3622 33.0362 19.4882 33.1526 19.606C33.2666 19.7205 33.3957 19.8223 33.5351 19.9133C33.6694 20.0031 33.8158 20.0807 33.9698 20.1425C34.12 20.2042 34.2785 20.2519 34.4395 20.2831C34.5992 20.3162 34.7653 20.3334 34.9295 20.3334C35.0924 20.3334 35.2573 20.3162 35.4208 20.2831C35.5793 20.2519 35.7371 20.2042 35.8911 20.1425C36.0419 20.0807 36.1871 20.0031 36.3245 19.9133C36.462 19.8223 36.5899 19.7205 36.7057 19.606C36.8203 19.4882 36.9259 19.3622 37.0195 19.2273C37.1098 19.0911 37.1875 18.9466 37.2511 18.7977C37.3141 18.6482 37.3606 18.4916 37.3937 18.3331C37.4268 18.1715 37.4414 18.0098 37.4414 17.8482C37.4414 17.6834 37.4268 17.5217 37.3937 17.3607C37.3606 17.2016 37.3141 17.045 37.2511 16.8955C37.1875 16.7472 37.1098 16.6027 37.0195 16.4659C36.9259 16.331 36.8203 16.2024 36.7057 16.0885C36.5899 15.9733 36.462 15.8709 36.3245 15.7799C36.1871 15.6901 36.0419 15.6138 35.8911 15.5507C35.7371 15.4884 35.5793 15.4413 35.4208 15.4095C35.0962 15.3446 34.7628 15.3446 34.4395 15.4095C34.2785 15.4413 34.12 15.4884 33.9698 15.5507C33.8158 15.6138 33.6694 15.6901 33.5351 15.7799C33.3957 15.8709 33.2666 15.9733 33.1526 16.0885C33.0362 16.2024 32.9305 16.3323 32.8402 16.4659C32.7511 16.6027 32.6715 16.7459 32.6098 16.8955C32.5455 17.045 32.4984 17.2016 32.4653 17.3607C32.4329 17.5217 32.415 17.6834 32.415 17.8482C32.415 18.0098 32.4329 18.1715 32.4653 18.3331C32.4984 18.4916 32.5455 18.6482 32.6098 18.7977", fill: "#004E89" }),
            React.createElement("mask", { id: "mask0", "mask-type": "alpha", maskUnits: "userSpaceOnUse", x: "15", y: "4", width: "106", height: "128" },
                React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M15.6924 4.35901H120.514V131.641H15.6924V4.35901Z", fill: "white" })),
            React.createElement("g", { mask: "url(#mask0)" },
                React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M112.974 117.221H35.6706C33.9128 117.221 32.3727 116.298 31.5078 114.919H91.7556C93.1443 114.919 94.2688 113.805 94.2688 112.433C94.2688 111.06 93.1443 109.947 91.7556 109.947H31.4366C32.1264 108.773 33.2949 107.909 34.6791 107.627C34.6816 107.626 34.6829 107.626 34.6854 107.624C34.8235 107.597 34.9642 107.577 35.1055 107.561C35.1297 107.558 35.1507 107.553 35.1742 107.551C35.339 107.535 35.5045 107.526 35.6706 107.526H112.974V117.221ZM101.273 9.33064H115.487V53.0343L109.586 49.8414C109.21 49.6377 108.795 49.5359 108.379 49.5359C107.965 49.5359 107.549 49.6377 107.173 49.8414L101.273 53.0343V9.33064ZM20.7181 20.4602C20.7181 15.5216 24.0688 11.124 28.8667 9.76403C29.8767 9.47638 30.9211 9.33064 31.9705 9.33064H96.2474V57.2282C96.2474 58.1058 96.7152 58.9192 97.4776 59.3672C98.2419 59.8146 99.1876 59.8305 99.966 59.4092L108.379 54.8563L115.487 58.7015V102.555H37.4411V26.5545C37.4411 25.1811 36.3172 24.068 34.9292 24.068C33.5412 24.068 32.4154 25.1811 32.4154 26.5545V103.093C31.0159 103.573 29.7303 104.363 28.6523 105.43C27.0199 107.045 26.0271 109.121 25.7986 111.361L25.7961 111.372C25.7801 111.515 25.7712 111.66 25.7623 111.803C25.761 111.827 25.7579 111.85 25.7572 111.873C25.7483 112.039 25.7458 112.207 25.7458 112.375C25.7458 117.788 30.1987 122.193 35.6706 122.193H115.487V126.669H31.9686C30.9198 126.669 29.8767 126.523 28.8642 126.236C24.0688 124.876 20.7181 120.477 20.7181 115.539V20.4602ZM120.514 105.041V6.84483C120.514 5.47209 119.388 4.35901 118 4.35901H31.9686C30.4495 4.35901 28.9393 4.5703 27.4794 4.98396C20.5393 6.95175 15.6924 13.3146 15.6924 20.4602V115.539C15.6924 122.684 20.5393 129.048 27.4768 131.014C28.9393 131.43 30.4495 131.641 31.9686 131.641H118C119.388 131.641 120.514 130.528 120.514 129.155V119.707C120.514 118.335 119.388 117.223 118 117.221V107.526C119.388 107.526 120.514 106.413 120.514 105.041V105.041Z", fill: "#004E89" })),
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M20.7183 20.4606C20.7183 15.5214 24.069 11.1238 28.8669 9.76439C29.8769 9.47673 30.9212 9.33099 31.9706 9.33099H96.2475V57.2279C96.2475 58.1055 96.7153 58.9189 97.4777 59.3669C98.2414 59.8149 99.1878 59.8309 99.9655 59.4096L108.379 54.856L115.488 58.7019V102.554H37.4412V26.5542C37.4412 25.1815 36.3173 24.0684 34.9293 24.0684C33.5407 24.0684 32.4155 25.1815 32.4155 26.5542V103.093C31.016 103.573 29.7305 104.363 28.6524 105.43C27.02 107.044 26.0272 109.12 25.7981 111.361L25.7962 111.372C25.7803 111.515 25.7707 111.66 25.7625 111.803C25.7612 111.827 25.758 111.85 25.7574 111.873C25.7485 112.039 25.7453 112.207 25.7453 112.375C25.7453 117.788 30.1982 122.193 35.6707 122.193H115.488V126.669H31.9687C30.9199 126.669 29.8769 126.524 28.8643 126.236C24.069 124.876 20.7183 120.477 20.7183 115.539V20.4606Z", fill: "#4783B1" }),
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M101.273 9.33081H115.488V53.0344L109.586 49.8416C109.211 49.6379 108.794 49.5361 108.38 49.5361C107.965 49.5361 107.549 49.6379 107.173 49.8416L101.273 53.0344V9.33081Z", fill: "#F1683F" }),
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M112.974 117.221H35.6706C33.9128 117.221 32.3727 116.298 31.5078 114.919H91.7556C93.1442 114.919 94.2688 113.805 94.2688 112.433C94.2688 111.06 93.1442 109.947 91.7556 109.947H31.4365C32.1264 108.773 33.2948 107.909 34.679 107.627C34.6816 107.626 34.6829 107.626 34.6854 107.624C34.8235 107.597 34.9642 107.577 35.1054 107.561C35.1296 107.558 35.1506 107.553 35.1742 107.551C35.339 107.535 35.5045 107.526 35.6706 107.526H112.974V117.221Z", fill: "white" })));
    }
}
ConstitutionalRightsLogo.displayName = "ConstitutionalRightsLogo";
