import manageJss from "@microsoft/fast-jss-manager-react";
import BaseMenu from "./menu";
import menuStyles from "./menu.style";
/*
 * The type returned by manageJss type is very complicated so we'll let the
 * compiler infer the type instead of re-declaring just for the package export
 */
/* tslint:disable-next-line:typedef */
const Menu = manageJss(menuStyles)(BaseMenu);
export { Menu };
