import manageJss from "@microsoft/fast-jss-manager-react";
import BaseCard from "./card";
import cardStyles from "./card.style";
/*
 * The type returned by manageJss type is very complicated so we'll let the
 * compiler infer the type instead of re-declaring just for the package export
 */
/* tslint:disable-next-line:typedef */
const Card = manageJss(cardStyles)(BaseCard);
export { Card };
