import React from "react";
import { CarouselSlideTheme } from '@microsoft/fast-components-react-msft';
import { Card } from "./components/card";
export function createCarouselSlidesFromCards(cards) {
    let slides = [];
    for (let i = 0; i < cards.length; i++) {
        slides[i] = {
            content: getContents(cards[i]),
            id: String(i + 1),
            theme: CarouselSlideTheme.light
        };
    }
    return slides;
}
export function getContents(cards) {
    return (className) => (React.createElement(Card, Object.assign({}, cards)));
}
