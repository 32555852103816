import React from "react";
export class KidsLikeYouLogo extends React.Component {
    render() {
        return (React.createElement("svg", { width: "125", height: "125", viewBox: "0 0 83 100", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M41.6808 34.433C40.8554 33.6082 40.0301 32.9897 38.9984 32.5773L39.6174 25.567L41.6808 12.9897L43.7442 25.567L44.3632 32.5773C43.3315 32.9897 42.5062 33.6082 41.6808 34.433ZM82.1236 85.567L72.0129 71.3402L70.3621 68.866L66.2353 44.1237L66.029 42.268C65.8226 41.4433 65.6163 40.4124 65.41 39.5876C65.2036 38.9691 64.9973 38.1443 64.9973 37.5258C64.9973 37.5258 64.9973 37.5258 64.9973 37.3196L54.0612 4.74227C53.2359 1.85567 50.5534 0 47.4583 0C45.3949 0 43.3315 0.824742 42.0935 2.47423C41.8871 2.8866 41.4745 3.29897 41.2681 3.50515C41.0618 3.09278 40.8554 2.68041 40.4428 2.47423C38.9984 0.824742 37.1413 0 35.0779 0C31.9828 0 29.3004 1.85567 28.2687 4.74227L17.5389 37.3196C17.5389 37.3196 17.5389 37.3196 17.5389 37.5258C17.1263 39.1753 16.7136 40.8247 16.5072 42.268L16.3009 43.0928L16.0946 44.1237L11.9678 68.866L10.317 71.3402L0.206341 85.567C0 85.9794 0 86.3917 0 87.0103C0 87.6289 0.412681 88.0412 0.825362 88.2474L17.9516 99.5876C18.3643 99.7938 18.5707 100 18.9833 100C19.6024 100 20.2214 99.7938 20.6341 99.1752L31.1574 84.9484L38.3793 75.2577C38.5857 75.0515 38.792 74.6392 38.792 74.433L41.0618 56.2887L43.3315 74.433C43.3315 74.8454 43.5379 75.0515 43.7442 75.2577L50.9661 84.9484L61.4895 99.1752C61.9022 99.7938 62.5212 100 63.1402 100C63.5529 100 63.9656 99.7938 64.1719 99.5876L81.2982 88.2474C81.7109 88.0412 82.1236 87.4227 82.1236 87.0103C82.5362 86.3917 82.3299 85.9794 82.1236 85.567Z", fill: "#004E89" }),
            React.createElement("path", { d: "M69.3304 24.1237C69.3304 24.9485 70.1558 25.7732 71.1875 25.7732H78.4094C79.4411 25.7732 80.2665 24.9485 80.2665 24.1237C80.2665 23.299 79.4411 22.4742 78.4094 22.4742H71.3939C70.3621 22.4742 69.3304 23.299 69.3304 24.1237Z", fill: "#004E89" }),
            React.createElement("path", { d: "M74.9017 40.2062C75.3144 40.6185 75.727 40.8247 76.3461 40.8247C76.7587 40.8247 77.3778 40.6185 77.7904 40.2062C78.6158 39.3814 78.6158 38.3505 77.7904 37.5257L73.0446 32.7835C72.2193 31.9587 70.9812 31.9587 70.3622 32.7835C69.5368 33.6082 69.5368 34.6391 70.3622 35.4639L74.9017 40.2062Z", fill: "#004E89" }),
            React.createElement("path", { d: "M71.3938 17.1134C71.8065 17.1134 72.4255 16.9072 72.8382 16.4948L77.584 11.7526C78.4094 10.9278 78.4094 9.89691 77.584 9.07217C76.7587 8.24743 75.5206 8.24743 74.9016 9.07217L70.1558 13.8144C69.3304 14.6392 69.3304 15.6701 70.1558 16.4948C70.3621 16.9072 70.7748 17.1134 71.3938 17.1134Z", fill: "#004E89" }),
            React.createElement("path", { d: "M3.09521 25.7732H10.1108C11.1425 25.7732 11.9679 25.1547 11.9679 24.1237C11.9679 23.299 11.1425 22.4742 10.1108 22.4742H3.09521C2.0635 22.4742 1.23814 23.0928 1.23814 24.1237C1.0318 25.1547 1.85716 25.7732 3.09521 25.7732Z", fill: "#004E89" }),
            React.createElement("path", { d: "M47.6647 72.7835L43.3315 40C43.3315 39.7938 43.3315 39.7938 43.3315 39.5876C43.3315 37.732 44.5696 36.0825 46.4267 35.6701C46.633 35.6701 46.8393 35.6701 47.0457 35.4639C48.0774 35.2577 49.3154 35.6701 50.1408 36.2887C50.9661 36.9072 51.5852 37.9381 51.7915 38.9691L53.4422 51.134C53.4422 51.5464 53.6486 51.9588 54.0613 52.1649C54.4739 52.5773 55.093 52.7835 55.712 52.7835C56.1247 52.7835 56.5373 52.5773 56.95 52.1649C57.3627 51.7526 57.569 51.134 57.569 50.5155L57.3627 48.866L55.9183 38.1443C55.712 36.0825 54.4739 34.0206 52.8232 32.7835C52.2042 32.3711 51.3788 31.9588 50.5535 31.5464C49.7281 31.3402 48.6964 31.134 47.871 31.134L47.252 24.5361C47.252 24.5361 47.252 24.5361 47.252 24.3299L44.3632 6.39175C44.1569 5.56701 44.5696 4.53608 45.1886 3.91752C45.8076 3.29897 46.633 2.8866 47.6647 2.8866C49.1091 2.8866 50.3471 3.71134 50.7598 4.94845L61.6959 37.5258C62.1085 38.9691 62.3149 40.4124 62.7276 41.8557L67.267 68.866C67.267 69.0722 67.4734 69.2783 67.4734 69.4845L68.2988 70.7216L53.6486 80.6185L47.6647 72.7835Z", fill: "#F8D0B2" }),
            React.createElement("path", { d: "M40.0301 40L35.6969 72.7835L29.713 80.6185L15.0629 71.3402L15.8882 70.1031C16.0946 69.8969 16.0946 69.6907 16.0946 69.4845L20.6341 42.4742C20.8404 41.0309 21.2531 39.5876 21.6658 38.1443L32.6018 5.56698C33.0145 4.32987 34.2525 3.50513 35.6969 3.50513C36.7286 3.50513 37.554 3.9175 38.173 4.53605C38.792 5.15461 38.9984 6.18554 38.9984 7.01028L36.1096 24.7422C36.1096 24.7422 36.1096 24.7422 36.1096 24.9484L35.4906 31.5464C33.6335 31.5464 31.9828 32.1649 30.5384 33.1958C28.8877 34.433 27.6496 36.4948 27.4433 38.5567L25.9989 49.2783L25.7926 50.9278C25.7926 51.5464 25.9989 52.1649 26.4116 52.5773C26.8243 52.9897 27.237 53.1958 27.6496 53.1958C28.2687 53.1958 28.8877 52.9897 29.3004 52.5773C29.5067 52.3711 29.713 51.9587 29.9194 51.5464L31.5701 39.3814C31.7765 38.3505 32.1891 37.3195 33.2208 36.701C34.0462 36.0824 35.2842 35.6701 36.3159 35.8763C36.5223 35.8763 36.7286 35.8763 36.935 36.0824C38.9984 36.0824 40.4428 37.9381 40.0301 40Z", fill: "#F8D0B2" }),
            React.createElement("path", { d: "M8.66625 16.701C9.07893 17.1134 9.49161 17.3196 10.1106 17.3196C10.5233 17.3196 11.1423 17.1134 11.555 16.701C12.3804 15.8763 12.3804 14.8454 11.555 14.0206L6.60284 9.07217C5.77748 8.24743 4.53944 8.24743 3.92041 9.07217C3.09505 9.89691 3.09505 10.9278 3.92041 11.7526L8.66625 16.701Z", fill: "#004E89" }),
            React.createElement("path", { d: "M8.66625 32.7835L3.92041 37.5257C3.09505 38.3505 3.09505 39.3814 3.92041 40.2062C4.33309 40.6185 4.74578 40.8247 5.3648 40.8247C5.77748 40.8247 6.3965 40.6185 6.80918 40.2062L11.555 35.4639C12.3804 34.6391 12.3804 33.6082 11.555 32.7835C10.5233 31.9587 9.49161 31.9587 8.66625 32.7835Z", fill: "#004E89" }),
            React.createElement("path", { d: "M57.3626 57.938C57.3626 57.938 57.3626 57.7318 57.1563 57.7318L56.9499 57.5257L56.7436 57.3195L56.5372 57.1133H56.3309C56.1246 57.1133 55.9182 57.1133 55.7119 57.1133C55.5055 57.1133 55.5055 57.1133 55.5055 57.1133C55.5055 57.1133 55.2992 57.1133 55.2992 57.3195L55.0929 57.5257L54.8865 57.7318L54.6802 57.938V58.1442C54.6802 58.1442 54.6802 58.3504 54.6802 58.5566C54.6802 58.7628 54.6802 58.7628 54.6802 58.969C54.6802 59.1751 54.6802 59.1751 54.6802 59.1751C54.6802 59.1751 54.6802 59.3813 54.8865 59.3813L55.0929 59.5875L55.2992 59.7937L55.5055 59.9999H55.7119C55.9182 59.9999 55.9182 59.9999 56.1246 59.9999C56.3309 59.9999 56.3309 59.9999 56.5372 59.9999C56.7436 59.9999 56.7436 59.9999 56.7436 59.9999C56.7436 59.9999 56.9499 59.9999 56.9499 59.7937L57.1563 59.5875C57.3626 59.3813 57.5689 58.969 57.5689 58.3504C57.5689 58.1442 57.5689 58.1442 57.5689 57.938C57.3626 58.1442 57.3626 57.938 57.3626 57.938Z", fill: "#004E89" }),
            React.createElement("path", { d: "M28.0624 57.938C28.0624 57.938 28.0624 57.7318 27.8561 57.7318L27.6497 57.5257L27.4434 57.3195L27.2371 57.1133H27.0307C26.8244 57.1133 26.618 57.1133 26.4117 57.1133C26.2054 57.1133 26.2054 57.1133 26.2054 57.1133C26.2054 57.1133 25.999 57.1133 25.999 57.3195L25.7927 57.5257L25.5863 57.7318L25.38 57.938V58.1442C25.38 58.1442 25.38 58.3504 25.38 58.5566C25.38 58.7628 25.38 58.7628 25.38 58.969C25.38 59.1751 25.38 59.1751 25.38 59.1751C25.38 59.1751 25.38 59.3813 25.5863 59.3813L25.7927 59.5875L25.999 59.7937L26.2054 59.9999H26.4117C26.618 59.9999 26.618 59.9999 26.8244 59.9999C27.0307 59.9999 27.0307 59.9999 27.2371 59.9999C27.4434 59.9999 27.4434 59.9999 27.4434 59.9999C27.4434 59.9999 27.6497 59.9999 27.6497 59.7937L27.8561 59.5875L28.0624 59.3813L28.2688 59.1751V58.969C28.2688 58.7628 28.2688 58.7628 28.2688 58.5566C28.2688 58.3504 28.2688 58.3504 28.2688 58.1442C28.0624 58.1442 28.0624 57.938 28.0624 57.938Z", fill: "#004E89" }),
            React.createElement("path", { d: "M63.7593 95.6701L55.2993 84.3299L69.9495 75.2577L77.9968 86.3917L63.7593 95.6701Z", fill: "#4783B1" }),
            React.createElement("path", { d: "M18.5706 95.6701L4.33313 86.3917L12.5868 75.2577L27.0306 84.3299L18.5706 95.6701Z", fill: "#F38665" })));
    }
}
KidsLikeYouLogo.displayName = "KidsLikeYouLogo";
