import React from "react";
export class BePreparedLogo extends React.Component {
    render() {
        return (React.createElement("svg", { width: "408", height: "408", viewBox: "0 0 139 136", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M129.241 35.088C127.884 35.088 126.526 35.36 125.168 35.904V16.048C125.168 10.88 121.096 6.8 115.937 6.8C114.579 6.8 112.95 7.072 111.864 7.888C111.593 5.984 110.507 4.352 109.149 2.992C107.248 1.36 105.076 0.272 102.361 0.272C97.2024 0.272 93.1297 4.624 93.1297 9.52V10.88C92.0436 10.336 90.6861 10.064 89.057 10.064C83.8982 10.064 79.8255 14.144 79.8255 19.312V23.392C78.7394 22.848 77.3818 22.576 76.0242 22.576C73.5806 22.576 71.137 23.392 69.2364 25.296C67.0642 23.392 64.6206 22.576 62.177 22.576C60.8194 22.576 59.4618 22.848 58.3758 23.392V19.312C58.3758 14.144 54.303 10.064 49.1442 10.064C47.7867 10.064 46.4291 10.336 45.0715 10.88V9.248C45.0715 4.08 40.9988 0 35.84 0C33.3964 0 30.9527 0.816 29.0521 2.72C27.6945 4.08 26.88 5.712 26.337 7.616C24.9794 7.072 23.6218 6.528 22.2642 6.528C17.377 6.528 13.3042 10.88 13.3042 16.048V35.904C12.2182 35.36 10.8606 35.088 9.23152 35.088C4.07273 35.088 0 39.168 0 44.336V78.336C0 86.224 3.80121 93.84 10.0461 98.464C11.6752 99.552 15.2048 104.992 17.6485 109.344C16.8339 109.888 16.2909 110.704 16.2909 111.792V133.28C16.2909 134.64 17.377 136 19.0061 136H57.0182C58.6473 136 59.7333 134.912 59.7333 133.28V113.424C61.9055 114.784 63.5345 115.872 64.8921 116.688C65.4352 117.232 65.9782 117.504 66.5212 117.776C67.3358 118.32 68.1503 118.32 68.9648 118.32C69.7794 118.32 70.5939 118.048 71.4085 117.776C71.9515 117.504 72.4945 117.232 73.0376 116.688C74.3952 115.872 76.2958 114.784 78.4679 113.424V133.28C78.4679 134.64 79.5539 136 81.183 136H119.195C120.553 136 121.91 134.912 121.91 133.28V112.064C121.91 110.976 121.367 110.16 120.553 109.616C122.996 105.264 126.798 99.824 128.155 98.736C134.4 94.112 138.201 86.496 138.201 78.608V44.336C138.473 39.168 134.4 35.088 129.241 35.088Z", fill: "#004E89" }),
            React.createElement("path", { d: "M13.5759 94.6561C8.6886 90.8481 5.43042 84.8641 5.43042 78.6081V44.6081C5.43042 42.4321 7.33103 40.8001 9.50315 40.8001C11.6753 40.8001 13.5759 42.4321 13.5759 44.6081V66.9121C13.5759 68.2721 14.6619 69.6321 16.291 69.6321C17.9201 69.6321 19.0062 68.5441 19.0062 66.9121V42.4321V16.0481C19.0062 13.8721 20.9068 12.2401 23.0789 12.2401C25.251 12.2401 27.1516 13.8721 27.1516 16.0481V34.2721C27.1516 35.6321 28.2377 36.9921 29.8668 36.9921C31.4959 36.9921 32.5819 35.9041 32.5819 34.2721V9.52006C32.5819 8.43206 33.125 7.34406 33.9395 6.52806C34.211 5.98406 35.2971 5.44006 36.3831 5.44006C38.5553 5.44006 40.1844 7.34406 40.1844 9.24806V44.0641C37.4692 45.1521 35.0256 46.7841 32.8535 48.6881C27.6947 53.5841 24.9795 60.3841 24.9795 68.5441C24.9795 71.2641 25.251 73.4401 25.5225 75.8881C24.4365 75.6161 23.0789 75.6161 21.7213 75.6161H21.4498C19.8207 75.6161 18.7347 76.7041 18.7347 78.3361C18.7347 79.6961 19.8207 81.0561 21.4498 81.0561H21.9928C24.4365 81.0561 26.6086 81.6001 28.5092 82.6881C28.7807 82.6881 28.7807 82.9601 29.0522 82.9601C29.0522 82.9601 29.0522 82.9601 29.3238 82.9601C29.5953 83.2321 29.8668 83.2321 29.8668 83.5041C29.8668 83.5041 29.8668 83.5041 30.1383 83.5041C30.4098 83.5041 30.4098 83.7761 30.6813 83.7761C33.668 86.2241 35.5686 89.7601 35.8401 93.5681C35.8401 93.8401 35.8401 94.1121 35.8401 94.6561V109.072H24.165C21.9928 105.808 16.8341 97.1041 13.5759 94.6561Z", fill: "#F8D0B2" }),
            React.createElement("path", { d: "M31.7672 41.888C31.7672 41.616 31.4957 41.616 31.4957 41.344C31.4957 41.072 31.2242 41.072 31.2242 40.8C31.2242 40.8 30.9527 40.528 30.6812 40.528C30.4096 40.528 30.4096 40.256 30.1381 40.256C29.8666 40.256 29.8666 40.256 29.5951 39.984C28.7806 39.712 27.6945 39.984 27.1515 40.8C27.1515 40.8 26.8799 41.072 26.8799 41.344C26.8799 41.616 26.6084 41.616 26.6084 41.888C26.6084 42.16 26.6084 42.16 26.3369 42.432C26.3369 42.704 26.3369 42.704 26.3369 42.976C26.3369 43.792 26.6084 44.336 27.1515 44.88C27.1515 44.88 27.423 45.152 27.6945 45.152C27.966 45.152 27.966 45.424 28.2375 45.424C28.509 45.424 28.509 45.424 28.7806 45.696C29.0521 45.696 29.0521 45.696 29.3236 45.696C29.5951 45.696 29.5951 45.696 29.8666 45.696C30.1381 45.696 30.1381 45.696 30.4096 45.424C30.6812 45.424 30.6812 45.152 30.9527 45.152C31.2242 45.152 31.2242 44.88 31.4957 44.88C32.0387 44.336 32.3102 43.52 32.3102 42.976C32.3102 42.704 32.3102 42.704 32.3102 42.432C31.7672 42.432 31.7672 42.16 31.7672 41.888Z", fill: "#004E89" }),
            React.createElement("path", { d: "M40.1843 109.616V99.824C43.714 103.088 47.5152 106.08 51.3164 108.8L50.7734 109.616H40.1843V109.616Z", fill: "#F8D0B2" }),
            React.createElement("path", { d: "M45.6146 19.0399C45.6146 16.8639 47.5152 15.2319 49.6873 15.2319C51.8595 15.2319 53.7601 16.8639 53.7601 19.0399V41.0719C53.4886 41.0719 53.217 41.0719 52.9455 41.0719H52.674C52.131 41.0719 51.8595 41.0719 51.3164 41.0719C49.4158 41.0719 47.2437 41.3439 45.6146 41.6159V19.0399V19.0399Z", fill: "#F8D0B2" }),
            React.createElement("path", { d: "M58.1042 31.8241C58.1042 29.6481 60.0049 28.0161 62.177 28.0161C63.263 28.0161 64.3491 28.2881 65.1636 29.1041C65.9782 29.9201 66.5212 31.0081 66.5212 32.0961V47.3281C66.2497 47.0561 66.2497 47.0561 65.9782 46.7841C65.9782 46.7841 65.9782 46.7841 65.7067 46.7841C65.4351 46.5121 65.4351 46.2401 65.1636 46.2401C64.8921 45.9681 64.6206 45.9681 64.6206 45.6961C64.6206 45.6961 64.6206 45.6961 64.3491 45.6961C64.0776 45.6961 63.8061 45.4241 63.8061 45.4241C63.5345 45.1521 63.263 45.1521 62.9915 44.8801C62.9915 44.8801 62.9915 44.8801 62.72 44.8801C62.4485 44.8801 62.177 44.6081 61.9055 44.6081C61.6339 44.6081 61.3624 44.3361 61.0909 44.3361H60.8194C60.5479 44.3361 60.2764 44.0641 60.0049 44.0641C59.7333 44.0641 59.4618 43.7921 59.1903 43.7921H58.9188C58.6473 43.7921 58.6473 43.7921 58.3758 43.5201V31.8241H58.1042Z", fill: "#F8D0B2" }),
            React.createElement("path", { d: "M133.042 78.88C133.042 85.136 130.056 91.12 124.897 94.928C121.639 97.376 116.48 106.08 114.308 109.888H102.361V95.2C102.361 94.928 102.361 94.656 102.361 94.112C102.633 90.304 104.533 86.496 107.52 84.048C107.791 83.776 107.791 83.776 108.063 83.776C108.063 83.776 108.063 83.776 108.334 83.776C108.606 83.504 108.877 83.504 108.877 83.232C108.877 83.232 108.877 83.232 109.149 83.232C109.421 83.232 109.421 82.96 109.692 82.96C111.593 81.872 113.765 81.328 116.208 81.328H116.751C118.381 81.328 119.467 80.24 119.467 78.608C119.467 77.248 118.381 75.888 116.751 75.888H116.208C114.851 75.888 113.765 75.888 112.407 76.16C112.95 73.984 112.95 71.536 112.95 68.816C112.95 66.368 112.679 63.92 112.136 61.744C111.05 56.848 108.606 52.496 105.076 49.232C102.904 47.328 100.461 45.696 97.7454 44.608V9.52004C97.7454 7.34404 99.3745 5.71204 101.547 5.71204C102.633 5.71204 103.447 5.98404 104.262 6.80004C105.076 7.61604 105.619 8.70404 105.619 9.79204V34.544C105.619 35.904 106.705 37.264 108.334 37.264C109.964 37.264 111.05 36.176 111.05 34.544V16.048C111.05 13.872 112.95 12.24 115.122 12.24C117.294 12.24 119.195 13.872 119.195 16.048V42.16V66.64C119.195 68 120.281 69.36 121.91 69.36C123.539 69.36 124.625 68.272 124.625 66.64V44.336C124.625 42.16 126.526 40.528 128.698 40.528C130.87 40.528 132.771 42.16 132.771 44.336V78.88H133.042Z", fill: "#F8D0B2" }),
            React.createElement("path", { d: "M96.9308 99.824V109.616H86.3417L85.7987 108.8C89.5999 106.08 93.4011 103.088 96.9308 99.824Z", fill: "#F8D0B2" }),
            React.createElement("path", { d: "M71.9514 31.824C71.9514 30.736 72.4944 29.648 73.309 28.832C74.1235 28.016 75.2096 27.744 76.2956 27.744C78.4678 27.744 80.3684 29.648 80.3684 31.824V42.976C80.0968 42.976 80.0968 42.976 79.8253 43.248H79.5538C79.2823 43.248 79.0108 43.52 78.7393 43.52C78.4678 43.52 78.1962 43.792 77.9247 43.792H77.6532C77.3817 43.792 77.1102 44.064 76.8387 44.064C76.5672 44.064 76.2956 44.336 76.0241 44.336C76.0241 44.336 76.0241 44.336 75.7526 44.336C75.4811 44.336 75.2096 44.608 74.9381 44.88C74.6666 44.88 74.395 45.152 74.395 45.424C74.395 45.424 74.395 45.424 74.1235 45.424C73.852 45.696 73.5805 45.696 73.5805 45.968C73.309 46.24 73.309 46.24 73.0375 46.512C73.0375 46.512 73.0375 46.512 72.766 46.512C72.4944 46.784 72.4944 46.784 72.2229 47.056V31.824H71.9514Z", fill: "#F8D0B2" }),
            React.createElement("path", { d: "M85.7989 19.0399C85.7989 16.8639 87.6995 15.2319 89.8716 15.2319C92.0437 15.2319 93.9443 16.8639 93.9443 19.0399V41.6159C93.6728 41.6159 93.6728 41.6159 93.4013 41.6159C91.7722 41.3439 89.8716 41.0719 87.971 41.0719C87.428 41.0719 87.1564 41.0719 86.6134 41.0719H86.3419C86.0704 41.0719 85.7989 41.0719 85.5273 41.0719V19.0399H85.7989Z", fill: "#F8D0B2" }),
            React.createElement("path", { d: "M80.3687 105.264C76.2959 107.984 72.4947 110.432 70.0511 111.792C69.7796 112.064 69.2366 112.336 68.965 112.336C68.6935 112.064 68.1505 111.792 67.879 111.52C65.4353 110.16 61.9056 107.712 57.8329 104.992C50.7735 100.368 45.0717 95.744 40.999 91.936C40.4559 87.584 38.2838 83.504 35.2972 80.512C35.0256 80.24 35.0256 80.24 34.7541 79.968L34.4826 79.696C34.4826 79.696 34.2111 79.696 34.2111 79.424C33.9396 79.152 33.9396 79.152 33.6681 78.88C33.125 78.608 32.582 78.336 32.3105 77.792C32.3105 77.792 32.3105 77.792 32.039 77.792C30.9529 74.8 30.6814 71.808 30.6814 68.272C30.6814 53.584 40.999 47.056 51.3165 47.056C52.4026 47.056 53.4887 47.056 54.5747 47.328C55.3893 47.328 55.9323 47.6 56.4753 47.6H56.7469C62.4487 49.232 65.1638 52.768 66.2499 55.216C66.2499 55.216 66.2499 55.216 66.2499 55.488C66.2499 55.488 66.2499 55.488 66.2499 55.76C66.5214 56.304 67.0644 56.576 67.879 56.848C67.879 56.848 67.879 56.848 68.1505 56.848C68.1505 56.848 68.1505 56.848 68.422 56.848H68.6935C68.965 56.848 69.2366 56.848 69.7796 56.576H70.0511C70.0511 56.576 70.3226 56.576 70.3226 56.304C70.3226 56.304 70.5941 56.304 70.5941 56.032L70.8656 55.76L71.1372 55.488L71.4087 55.216C73.0378 52.224 77.1105 47.056 86.6135 47.056C96.9311 47.056 107.249 53.584 107.249 68.272C107.249 71.264 106.977 74.256 106.163 76.704C106.163 76.976 105.891 77.52 105.891 77.792C105.891 77.792 105.891 77.792 105.62 77.792C105.348 78.064 104.805 78.336 104.534 78.608L104.262 78.88C103.991 79.152 103.99 79.152 103.719 79.424C103.719 79.424 103.447 79.424 103.447 79.696L103.176 79.968C102.904 80.24 102.904 80.24 102.633 80.512C99.6463 83.504 97.4741 87.584 96.9311 91.936C93.1299 96.016 87.4281 100.368 80.3687 105.264Z", fill: "#F38665" }),
            React.createElement("path", { d: "M54.846 115.328H21.7212V130.56H54.846V115.328Z", fill: "#F2F2D9" }),
            React.createElement("path", { d: "M117.295 115.328H84.1697V130.56H117.295V115.328Z", fill: "#F2F2D9" }),
            React.createElement("path", { d: "M111.864 41.8879C111.864 41.6159 111.593 41.6159 111.593 41.3439C111.593 41.0719 111.321 41.0719 111.321 40.7999C111.321 40.7999 111.05 40.5279 110.778 40.5279C110.507 40.5279 110.507 40.2559 110.235 40.2559C109.964 40.2559 109.964 40.2559 109.692 39.9839C109.421 39.9839 108.878 39.9839 108.606 39.9839C108.334 39.9839 108.334 39.9839 108.063 40.2559C107.791 40.2559 107.791 40.5279 107.52 40.5279C107.248 40.5279 107.248 40.7999 106.977 40.7999C106.977 40.7999 106.705 41.0719 106.705 41.3439C106.705 41.6159 106.434 41.6159 106.434 41.8879C106.434 42.1599 106.434 42.1599 106.162 42.4319C106.162 42.7039 106.162 42.7039 106.162 42.9759C106.162 43.2479 106.162 43.2479 106.162 43.5199C106.162 43.7919 106.162 43.7919 106.434 44.0639C106.434 44.3359 106.705 44.3359 106.705 44.6079C106.705 44.8799 106.977 44.8799 106.977 45.1519C106.977 45.1519 107.248 45.4239 107.52 45.4239C107.791 45.4239 107.791 45.6959 108.063 45.6959C108.334 45.6959 108.334 45.6959 108.606 45.9679C108.878 45.9679 108.878 45.9679 109.149 45.9679C109.421 45.9679 109.421 45.9679 109.692 45.9679C109.964 45.9679 109.964 45.9679 110.235 45.6959C110.507 45.6959 110.507 45.4239 110.778 45.4239C111.05 45.4239 111.05 45.1519 111.321 45.1519C111.321 45.1519 111.593 44.8799 111.593 44.6079C111.593 44.3359 111.864 44.3359 111.864 44.0639C111.864 43.7919 111.864 43.7919 112.136 43.5199C112.136 43.2479 112.136 43.2479 112.136 42.9759C112.136 42.7039 112.136 42.7039 112.136 42.4319C112.136 42.4319 112.136 42.1599 111.864 41.8879Z", fill: "#004E89" })));
    }
}
BePreparedLogo.displayName = "BePreparedLogo";
