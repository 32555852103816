import manageJss from "@microsoft/fast-jss-manager-react";
import BasePreparationPlan from "./preparation-plan";
import preperationPlanStyles from "./preparation-plan.style";
/*
 * The type returned by manageJss type is very complicated so we'll let the
 * compiler infer the type instead of re-declaring just for the package export
 */
/* tslint:disable-next-line:typedef */
const PreparationPlan = manageJss(preperationPlanStyles)(BasePreparationPlan);
export { PreparationPlan };
export * from "./preparation-plan.logo";
